import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Full Stack Open 2021",
    years: "2021",
    content:
      "Learned modern web dev based in the MERN stack while covering Redux, Typescript and GraphQL.",
    link: './cert/FO.png'
  },
  {
    id: 2,
    title: "CS50W by Harvard University",
    years: "2020",
    content:
      "The course covered web dev based in Python using the Django framework.",
    link: './cert/CS50W.png'
  },
  {
    id: 3,
    title: "Ultimate MySQL Bootcamp by Udemy",
    years: "2020",
    content:
    "From basic query building to indexing - learned major aspects of SQL",
    link: './cert/SQL.png'
  }
];

const experienceData = [
  {
    id: 1,
    title: "Specialist - Corporate Strategy, Grameenphone",
    years: "2020 - Present",
    content:
      "Fostering the growth and diversification of the organization by putting new projects in the pipeline.",
  },
  {
    id: 2,
    title: "Specialist - Pricing, Grameenphone",
    years: "2019 - 2020",
    content:
      "Acted as a gatekeeper of the company ensuring consistent P&L.",
  },
  {
    id: 3,
    title: "Senior Executive - Marketing, Grameenphone",
    years: "2016 - 2019",
    content:
      "Worked in different roles from Category Brands to Corporate Brand.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
