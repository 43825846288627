import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Ishmam",
  avatarImage: "/images/avatar2.svg",
  contentHeader:
    "After completing my BBA from Institute of Business Administration, University of Dhaka, I've spent the past 5 years in the telecom industry in Bangladesh.",
  contentBody: "Only recently did I stumble upon the world of programming and absolutely have been loving the journey!"
};

const progressData = [
  {
    id: 1,
    title: "Full Stack Web Development",
    percantage: 70,
    years: '1 year',
    progressColor: "#0BA20B",
  },
  {
    id: 2,
    title: "Python Programming",
    percantage: 80,
    years: '1.5 years',
    progressColor: "#6C6CE5",
  },
  {
    id: 3,
    title: "UI/UX Design",
    years: '6 months',
    percantage: 50,
    progressColor: "#FF4C60",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 6,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cups of Lemonade",
    count: 1547,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 2,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Awards winner",
    count: 0,
    icon: "icon-badge",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.contentHeader}</p>
                  <p>{aboutContent.contentBody}</p>
                  <div className="mt-3">
                    <a href="https://drive.google.com/file/d/1Q0Yc1YPKILAfsj8ib_Ljv1qh2CvY7eb1/view?usp=sharing" className="btn btn-default" target="_blank" rel='noreferrer'>
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
