import React from "react";
// import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

export const allBlogs = [
  {
    id: 1,
    title: "Hello, World!",
    image: "images/blog/1.svg",
    filesource: "../../blogs/hello-world.md",
    date: "01 July, 2021",
    author: "Ishmam",
    category: "Journal",
  },
  {
    id: 2,
    title: "ITL? - v1",
    image: "images/blog/itl-v1.svg",
    filesource: "../../blogs/itl-v1.md",
    date: "11 July, 2021",
    author: "Ishmam",
    category: "Journal",
  },
  // {
  //   id: 3,
  //   title: "3 Things To Know About Startup Business",
  //   image: "images/blog/3.svg",
  //   filesource: "../../blogs/things-to-know-about-startup-business.md",
  //   date: "06 February, 2020",
  //   author: "Bolby",
  //   category: "Business",
  // },
];

function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Latest Posts" />
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        {/* <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div> */}
      </div>
    </section>
  );
}

export default Blogs;
