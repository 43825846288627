import React, { useState, useEffect, createRef } from "react";
// import ReactMarkdown from "react-markdown/with-html";
import ReactMarkdown from 'react-markdown'
import {} from 'react-markdown/'
import axios from "axios";
import Disqus from "disqus-react";
import Header from "../components/layouts/Header";
import ProgressBar from "../components/elements/ProgressBar";
// import Loading from "../../public/images/loading.gif";
import SocialShare from '../components/elements/SocialShare'
import { allBlogs } from "../components/sections/Blogs";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
      {
        width: 1280,
        height: 600
      }
    )
  const target = createRef();
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    // console.log('Checking ->', require(`../blogs/${blogFile}.md`))

    axios.get(`../../../../../data/${blogFile}.md`).then((result) => {
      setContent(result.data);

      setWindowDimensions(getWindowDimensions())
    });
    
    const title = allBlogs.find(blog => blog.id === parseInt(blogId)).title
    
    document.title = `${title} | Ishmam Chowdhury`
    
  }, [content, blogFile, blogId]);
  
  console.log('windowDimensions ->', windowDimensions )
  
  const disqusShortname = "ishmam-portfolio"; //found in your Disqus.com dashboard
  const disqusConfig = {
    url: "https://www.ishmamchowdhury.com/", //Homepage link of this site.
    identifier: blogId,
    title: blogFile,
  };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <ProgressBar target={target} />
      <main ref={target} className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
              {!content && (
                <>
                <img src='../../../images/loading.gif' alt='loading' style={{ height: '100px', width: 'auto' }} />
                </>
              )}
              {content && (
                <div className={windowDimensions.width < 600 ? 'markDownSmall' : 'markDown'}>
                  <ReactMarkdown>
                    {content}
                  </ReactMarkdown>
                </div>
              )}
              <h3>Share!</h3>
              <SocialShare details={`${blogId}/${blogFile}`} />
              <h3>Comments?</h3>
              <div className="mi-blog-details-comments mt-30">
                <Disqus.DiscussionEmbed
                  shortname={disqusShortname}
                  config={disqusConfig}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default BlogDetails;
