import React, { useEffect } from "react";
import { Link } from "react-scroll";
import {
  useLocation, Link as ReactLink
} from "react-router-dom";
// import Logo from "../elements/Logo";
import { allBlogs } from '../sections/Blogs'

const getNospaceTitle = (filesource) => {
  let tempArr = filesource.split("/");
  let fileName = tempArr[tempArr.length - 1];
  let getName = fileName.slice(0, -3);
  return getName;
};

function Header({ light, logoSource, toggleMenu, headerToggler }) {
  const location = useLocation();
  const locationStatus = location.pathname.split('/').length === 2
  // console.log('locationStatus ->', locationStatus);
  // console.log('location ->', location.pathname.split('/'));

  useEffect(() => {
    if (locationStatus && !document.title.includes('Portfolio')) {
      document.title = 'Ishmam Chowdhury | Portfolio'
    }
  }, [locationStatus])


  const handleClasses = () => {
    let classes = "desktop-header-1 d-flex align-items-start flex-column";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  const handleMobileClasses = () => {
    let classes = "mobile-header-1";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container" >
          <div className="menu-icon d-inline-flex mr-4">
            <button onClick={headerToggler}>
              <span></span>
            </button>
          </div>
          {/* <Logo logoSource={logoSource} /> */}
        </div>
      </header>
      <header className={handleClasses()}>
        {/* <Logo logoSource={logoSource} /> */}
        <nav>
          {locationStatus && (
            <ul className="vertical-menu scrollspy">
            <li>
              <Link
                activeClass="active"
                to="section-home"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-home"></i>Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-about"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-user-following"></i>About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-services"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-briefcase"></i>Services
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-experiences"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-graduation"></i>Experience
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-works"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-layers"></i>Works
              </Link>
            </li>
            {/* <li>
              <Link
                activeClass="active"
                to="section-pricing"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-wallet"></i>Pricing
              </Link>
            </li> */}
            <li>
              <Link
                activeClass="active"
                to="section-blogs"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-note"></i>Blog
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="section-contact"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-bubbles"></i>Contact
              </Link>
            </li>
          </ul>
          )}
          {!locationStatus && (
            <ul className="vertical-menu scrollspy">
              <li>
                {/* <Link
                  activeClass="active"
                  to="section-home"
                  spy={true}
                  smooth={true}
                  duration={500}
                > */}
                  <ReactLink to='/'>

                    <i className="icon-home"></i>Home
                  </ReactLink>
                {/* </Link> */}
              </li>
              {allBlogs.map((blog) => (
                <li key={blog.id}>
                <ReactLink to={`../../../blogs/${blog.id}/${getNospaceTitle(blog.filesource)}`}>
                  <i className="icon-note"></i>{blog.title}
                </ReactLink>
                </li>
              ))}
            </ul>
          )}
        </nav>

        <div className="footer">
          <span className="copyright">
            &copy; {new Date().getFullYear()} Ishmam Chowdhury
          </span>
        </div>
      </header>
    </>
  );
}

export default Header;
