import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon
} from 'react-share';
import { quote, hashtag, description, size } from '../constants';

const SocialShare = ({ details }) => {
    
    const baseUrl = 'https://www.ishmamchowdhury.com/blogs/'
    const url = `${baseUrl}${details}`

    return (
            <>
                <FacebookShareButton
                    url={url}
                    quote={quote}
                    hashtag={hashtag}
                    description={description}>
                    <FacebookIcon size={size} round style={{ marginRight: '10px' }} />
                </FacebookShareButton>
                <WhatsappShareButton
                    url={url}
                    quote={quote}
                    hashtag={hashtag}
                    description={description}>
                    <WhatsappIcon size={size} round style={{ marginRight: '10px' }} />
                </WhatsappShareButton>
                <TwitterShareButton
                    url={url}
                    quote={quote}
                    hashtag={hashtag}
                    description={description}>
                    <TwitterIcon size={size} round style={{ marginRight: '10px' }} />
                </TwitterShareButton>
                <TelegramShareButton
                    url={url}
                    quote={quote}
                    hashtag={hashtag}
                    description={description}>
                    <TelegramIcon size={size} round />
                </TelegramShareButton>
        </>
    );
};

export default SocialShare